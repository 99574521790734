import React from "react";
import { Routes, Route } from 'react-router-dom';
import Posts from './Posts';
import Home from './Home';
import Navbar from "../common/Navbar";
import Single from "./Single";
import CategoryArticles from "../Postscat";
import Footer from "../Footer";
import AboutPage from "../AboutPage";
import ContactsList from "../ContactsList";
import MyFullCalendar from "../MyFullCalendar";




const Pages = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/posts" element={<Posts />} />
        <Route path="/annuaire" element={<ContactsList />} />
        <Route path="/a-propos" element={<AboutPage />} />
        <Route path="/posts/:id" element={<Single />} />
        <Route path="/calendrier" element={<MyFullCalendar />} />
        <Route path="/articles/:categoryId" element={<CategoryArticles />} />
      </Routes>
      <Footer/>
      {/* <Footer/> */}
    </>
  );
};

export default Pages;
