import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import he from 'he';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const CategoryArticles = () => {
  const { categoryId } = useParams();
  const [category, setCategory] = useState({});
  const [categoryPosts, setCategoryPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const perPage = 16;
  const [loading, setLoading] = useState(true);
  const [postDates, setPostDates] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryUrl = `${process.env.REACT_APP_API_ROOT}/categories/${categoryId}`;
        const categoryResponse = await axios.get(categoryUrl);
        setCategory(categoryResponse.data);

        const postsUrl = `${process.env.REACT_APP_API_ROOT}/posts?categories=${categoryId}&per_page=${perPage}&page=${currentPage}`;
        const postsResponse = await axios.get(postsUrl);
        setCategoryPosts(postsResponse.data);
        const { data, headers } = postsResponse;
        setTotalPages(Number(headers['x-wp-totalpages']));

        // Nouvelle requête pour obtenir les dates d'ajout des articles
        const dates = await Promise.all(
          postsResponse.data.map(async (post) => {
            const postUrl = `${process.env.REACT_APP_API_ROOT}/posts/${post.id}`;
            const postResponse = await axios.get(postUrl);
            return { id: post.id, date: postResponse.data.date };
          })
        );

        // Mettez à jour l'état des dates
        const datesObject = dates.reduce((acc, date) => {
          acc[date.id] = date.date;
          return acc;
        }, {});

        setPostDates(datesObject);


        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [categoryId, currentPage]);

  // Truncate the description to 50 characters
  const truncateDescription = (description) => {
    if (description.length > 60) {
      return description.substring(0, 60) + '...';
    }
    return description;
  };

  const generateWhatsAppLink = (text) => {
    const encodedText = encodeURIComponent(text);
    return `https://wa.me/?text=${encodedText}`;
  };

  return (
    <div className="container mx-auto mb-10 p-1 mt-20">
      <h1 className='lg:w-5/6 xl:w-4/5 2xl:w-3/4 mx-6 py-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-5 text-2xl font-bold'>
        {category.name}:
      </h1>

      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-5' style={{ marginTop: '-20px' }}>
        {loading ? (
          <div className='col-span-3 w-16 h-16 absolute left-1/2 top-1/2 rounded-full border-4 border-b-0 border-blue-500 animate-spin' />
        ) : categoryPosts.length ? (
          categoryPosts.map((post) => (
            <div key={post.id} className="card shadow-lg rounded-lg flex flex-col gap-5 p-4 bg-white">
              <Link to={`/posts/${post.id}`}>
                <img
                  src={post.featured_src || 'https://via.placeholder.com/500'}
                  alt={post.title.rendered}
                  style={{ width: 500, height: 200 }}
                />
              </Link>
              <Link to={`/posts/${post.id}`}>
                <h2 className="text-lg font-medium" style={{ fontWeight: 'bolder', fontSize: 18 }}>{truncateDescription(he.decode(post.title.rendered), 60)}</h2>
                <p style={{ marginTop: 4, fontWeight:'bolder' }} className="text-gray-500 text-sm">publié le {new Date(postDates[post.id]).toLocaleDateString()}</p>

              </Link>
              <button
                  className='bg-green-500 text-white px-2 py-1 rounded mt-2'
                  onClick={() => {
                    const messageText = `Consultez cet article: ${he.decode(post.title.rendered)} - http://localhost:3000/posts/${post.id}`;
                    const whatsappLink = generateWhatsAppLink(messageText);
                    window.open(whatsappLink, '_blank');
                  }}
                  style={{fontWeight:'bolder', fontSize:18, marginTop:'-10px'}}
                >
                <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: '5px' }} />
                Partager sur whatsapp
              </button>
            </div>
          ))
        ) : (
          <p>Aucun contenu ajouté à cette catégorie.</p>
        )}
      </div>

      {/* Pagination */}
      <center>
        <div className="" style={{ marginBottom: '40px' }}>
          <button className='btn-primary text-white bg-blue-500 rounded-lg hover:shadow-lg disabled:opacity-50' disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)} style={{ marginRight: '5px', padding: 8, fontSize: 16, background: '#002763' }}>Précédent</button>
          <span style={{ margin: '0 5px', fontSize: 17 }}>{currentPage} sur {totalPages}</span>
          <button className='btn-primary text-white bg-blue-500 rounded-lg hover:shadow-lg disabled:opacity-50' onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages} style={{ marginLeft: '5px', padding: 8, fontSize: 16, background: '#002763' }}>Suivant</button>
        </div>
      </center>
    </div>
  );
};

export default CategoryArticles;
