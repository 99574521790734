import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link to="/" className="text-white text-lg font-bold flex items-center">
            <img src="https://www.adcocody.ci/img/logo.png" alt="Logo AD-Excellence.net" className="h-10" />
            <span className="ml-2">AD-Excellence.net</span>
          </Link>
          <button
            onClick={toggleMenu}
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-multi-level"
            aria-expanded={isMenuOpen}
          >
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
            </svg>
          </button>
          <div className={`w-full md:flex md:w-auto ${isMenuOpen ? 'block' : 'hidden'}`} id="navbar-multi-level">
            <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              

        <li>
        <Link
              to="/a-propos"
              className="block mt-4 lg:inline-block lg:mt-0 hover:text-gray-500 rounded-md bg-white shadow-md"
              style={{ fontWeight: 'bolder', padding: '4px 8px', fontSize:16, background:'#1c1d22', color:'white', border:'1px solid #565869'  }}
            >
              A Propos
            </Link>

        </li>
        <li>
        <Link
              to="/articles/205"
              className="block mt-4 lg:inline-block lg:mt-0 hover:text-gray-500 rounded-md bg-white shadow-md"
              style={{ fontWeight: 'bolder', padding: '4px 8px', fontSize:16, background:'#1c1d22', color:'white', width:75, border:'1px solid #565869'  }}
            >
              Projets
            </Link>

        </li>
        <li>
        <Link
              to="/calendrier"
              className="block mt-4 lg:inline-block lg:mt-0 hover:text-gray-500 rounded-md bg-white shadow-md"
              style={{ fontWeight: 'bolder', padding: '4px 8px', fontSize:16, background:'#1c1d22', color:'white', width:115, border:'1px solid #565869' }}
            >
              Calendrier
            </Link>

        </li>
        <li>
        <Link
              to="/annuaire"
              className="block mt-4 lg:inline-block lg:mt-0 hover:text-gray-500 rounded-md bg-white shadow-md"
              style={{ fontWeight: 'bolder', padding: '4px 8px', fontSize:16, background:'#1c1d22', color:'white', width:171, border:'1px solid #565869'  }}
            >
              Annuaire de l'église
            </Link>
        </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
