import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import he from 'he';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [postDates, setPostDates] = useState({});

  const perPage = 8;

  const generateWhatsAppLink = (text) => {
    const encodedText = encodeURIComponent(text);
    return `https://wa.me/?text=${encodedText}`;

  };

  const fetchPosts = async (page) => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT}/posts?per_page=${perPage}&page=${page}`;
      const response = await axios.get(url);
      const fetchedPosts = response.data;
      const { data, headers } = response;
      setTotalPages(Number(headers['x-wp-totalpages']));

      const postsWithImages = await Promise.all(
        fetchedPosts.map(async (post) => {
          if (post.featured_media) {
            const imageUrl = await fetchFeaturedImage(post.featured_media);
            return { ...post, imageUrl };
          }
          return post;
        })
      );

      setPosts(postsWithImages);
      // Récupérez les dates de création des articles
    const dates = await Promise.all(
      fetchedPosts.map(async (post) => {
        return { id: post.id, date: post.date };
      })
    );

    // Mettez à jour l'état des dates
    const datesObject = dates.reduce((acc, date) => {
      acc[date.id] = date.date;
      return acc;
    }, {});

    setPostDates(datesObject);

    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  useEffect(() => {
    fetchPosts(currentPage);
  }, [currentPage]);

  const fetchFeaturedImage = async (mediaId) => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT}/media/${mediaId}`;
      const response = await axios.get(url);
      return response.data.source_url;
    } catch (error) {
      console.error(`Error fetching featured image for media ID ${mediaId}:`, error);
      return '';
    }
  };

  const truncateDescription = (description) => {
    if (description.length > 60) {
      return description.substring(0, 60) + '...';
    }
    return description;
  };



  useEffect(() => {
    if (posts.length > 0) {
      // Supprimez les balises existantes
      const metaTags = document.head.querySelectorAll('[property^="og:"]');
      metaTags.forEach(tag => tag.remove());
  
      // Mettez à jour les balises Open Graph avec les valeurs de votre choix
      const ogTitleTag = document.createElement('meta');
      ogTitleTag.setAttribute('property', 'og:title');
      ogTitleTag.setAttribute('content', 'Nouveau titre Open Graph');
      document.head.appendChild(ogTitleTag);
  
      const ogDescriptionTag = document.createElement('meta');
      ogDescriptionTag.setAttribute('property', 'og:description');
      ogDescriptionTag.setAttribute('content', 'Nouvelle description Open Graph');
      document.head.appendChild(ogDescriptionTag);
  
      const ogUrlTag = document.createElement('meta');
      ogUrlTag.setAttribute('property', 'og:url');
      ogUrlTag.setAttribute('content', 'http://nouvelle-url.com');
      document.head.appendChild(ogUrlTag);
  
      const ogImageTag = document.createElement('meta');
      ogImageTag.setAttribute('property', 'og:image');
      ogImageTag.setAttribute('content', posts[0].imageUrl || 'https://via.placeholder.com/500');
      document.head.appendChild(ogImageTag);
    }
  }, [posts]);
  

  useEffect(() => {
    // Mettre à jour les balises Open Graph ici
    if (posts.length > 0) {
      const metaTags = document.getElementsByTagName('meta');
      Array.from(metaTags).forEach(tag => {
        if (tag.getAttribute('property') === 'og:image') {
          tag.remove();
        }
      });
      const ogImageTag = document.createElement('meta');
      ogImageTag.setAttribute('property', 'og:image');
      ogImageTag.setAttribute('content', posts[0].imageUrl || 'https://via.placeholder.com/500');
      document.head.appendChild(ogImageTag);
    }
  }, [posts]);

  return (
    <>

<div className="container mx-auto my-auto mb-10 p-1" style={{marginTop:'-20px'}}>
        <h1 className='lg:w-5/6 xl:w-4/5 2xl:w-3/4 mx-6 py-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-5 text-2xl font-bold'>Derniers articles</h1>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-5'style={{marginTop:'-20px'}}>
          {Object.keys(posts).length ? (
            posts.map((post, index) => (
              <div key={post.id} className='card shadow-lg rounded-lg flex flex-col gap-5 p-4 bg-white'>
                <Link to={`/posts/${post.id}`}>
                  <img src={post.imageUrl || 'https://via.placeholder.com/500'} alt={post.title.rendered} style={{ width: 500, height: 200 }} />
                </Link>
                <Link to={`/posts/${post.id}`}>
                  <h2 className="text-lg font-medium" style={{fontWeight:'700', fontSize:18}}>{truncateDescription(he.decode(post.title.rendered), 60)}</h2>
                  <p style={{marginTop:4}} className="text-gray-500 text-sm">publié le {new Date(postDates[post.id]).toLocaleDateString()}</p>
                </Link>
                

                <button
                  className='bg-green-500 text-white px-2 py-1 rounded mt-0'
                  onClick={() => {
                    const messageText = `Consultez cet article: ${he.decode(post.title.rendered)} - http://localhost:3000/posts/${post.id}`;
                    const whatsappLink = generateWhatsAppLink(messageText);
                    window.open(whatsappLink, '_blank');
                  }}
                  style={{fontSize:18, marginTop:'-10px'}}
                >
                <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: '5px' }} />
                Partager sur whatsapp
              </button>
              </div>
            ))
          ) : (
            <div className='col-span-3 w-5 h-5 rounded-full border-2 border-spacing-2 animate-spin'/>
          )}
        </div>
      </div>
      <center>
        <div className='' style={{ marginBottom: '40px' }}>
          <button className='btn-primary text-white bg-blue-500 rounded-lg hover:shadow-lg disabled:opacity-50' disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)} style={{ marginRight: '5px', padding: 8, fontSize: 16, background:'#002763' }}>Précédent</button>
          <span style={{ margin: '0 5px', fontSize:17 }}>{currentPage} sur {totalPages}</span>
          <button className='btn-primary text-white bg-blue-500 rounded-lg hover:shadow-lg disabled:opacity-50' onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages} style={{ marginLeft: '5px', padding: 8, fontSize: 16, background:'#002763' }}>Suivant</button>
        </div>
      </center>

    </>
  );
};

export default Posts;
