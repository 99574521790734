import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white p-4 " style={{background:''}}>
      <div className="container mx-auto">

    <center>Abidjan Yopougon Agbayaté  - +225 07 88 63 55</center>
      </div>
    </footer>
  );
};

export default Footer;
