import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SliderComponent from "../../SliderComponent";
import Posts from "../Posts";
import PredicationPosts from "../../PostPredication";
import ProjetPosts from "../../../ProjetPosts";

const Home = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const url = `${process.env.REACT_APP_API_ROOT}/categories`;
        const response = await axios.get(url);
        const categoriesWithCount = await Promise.all(
          response.data.map(async (category) => {
            const count = await fetchArticleCount(category.id);
            return { ...category, count };
          })
        );
        // Exclude the "Non classé" category and "PROJETS" category
        const filteredCategories = categoriesWithCount.filter(
          (category) => category.name !== "Non classé" && category.name !== "PROJETS"
        );
        setCategories(filteredCategories);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const fetchArticleCount = async (categoryId) => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT}/posts?categories=${categoryId}`;
      const response = await axios.get(url);
      return response.data.length;
    } catch (error) {
      console.error(
        `Error fetching article count for category ${categoryId}:`,
        error
      );
      return 0;
    }
  };

  return (
    <div className="text-xl font-bold">
      <SliderComponent />

      {loading ? (
        <div className='col-span-3 w-16 h-16 absolute left-1/2 top-1/2 rounded-full border-4 border-b-0 border-blue-500 animate-spin'/>
      ) : categories.length === 0 ? (
        <p>Aucune catégorie pour le moment</p>
      ) : (
        <>
          <div className="container mx-auto my-auto mb-0 p-1">
            <div
              className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 p-5"
              style={{ marginTop: 10 }}
            >
              {categories.map((category) => (
                <Link to={`/articles/${category.id}`} key={category.id}>
                  <div
                    className="p-4 bg-white shadow-md rounded-md"
                    style={{ backgroundColor: "#002763", color: "white" }}
                  >
                    <Link to={`/articles/${category.id}`}>
                      <h2 className="text-lg font-bold mb-" style={{ fontSize: 16 }}>
                        {category.name}:{" "}
                        <span className="bg-red-500 text-white px-2 py-1 rounded-full" style={{ fontSize: 12 }}>
                          {category.count}
                        </span>
                      </h2>
                    </Link>
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <Posts />

          {/*
          <PredicationPosts />
          <ProjetPosts />          
          
           */}
        </>
      )}
    </div>
  );
};

export default Home;
