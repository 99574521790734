import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import he from 'he';

const Single = () => {
  const { id } = useParams();
  const [post, setPost] = useState({});
  const [postDates, setPostDates] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Requête pour obtenir les détails de l'article
        const postUrl = `${process.env.REACT_APP_API_ROOT}/posts/${id}`;
        const postResponse = await axios.get(postUrl);
        setPost(postResponse.data);

        // Requête pour obtenir la date de publication de l'article
        const postDateUrl = `${process.env.REACT_APP_API_ROOT}/posts/${id}?_fields=date`;
        const postDateResponse = await axios.get(postDateUrl);
        setPostDates({ ...postDates, [id]: postDateResponse.data.date });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <>
      {Object.keys(post).length ? (
        <div className="container mx-auto p-10 bg-white mt-20" style={{ marginBottom: 100 }}>
          <div className="mb-5">
            <img src={post.featured_src} alt={post.title.rendered} className="w-full h-auto rounded-lg" />
          </div>
          <div>
            <h1 className="text-3xl font-bold mb-3">{he.decode(post.title.rendered)}</h1>
            <p className="text-gray-500 text-sm" style={{fontWeight:'bolder', marginBottom:10, fontSize:16}}>Date de publication : {new Date(postDates[id]).toLocaleDateString()}</p>
          </div>
          <div className="text-lg leading-relaxed" dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div>
        </div>
      ) : (
        <p className="text-center">Loading...</p>
      )}
    </>
  );
};

export default Single;
